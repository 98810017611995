<template>
  <div>
    <!--<PageHeader pagetitle="Downloadables" :img="img"/>-->

    <v-data-table
    :headers="headers"
    :items="departmentDownloadables"
    :items-per-page="15"
    class="elevation-1"
    dense
    >
             
    <template #item.Url="{ item }">
        <a target="_blank" :href="$api_url+item.Url">
            {{ item.Name }}
        </a>
    </template>

    <!--<template #item.link="{item}">
         {{ item.value=departmentDetails[0].name }}
    </template>-->
    </v-data-table>   
    
  </div>
  
 
</template>

<script>
//import PageHeader from '@/components/PageHeader'
//import downloadablesData from "@/json/downloadables.json";
//import departmentData from "@/json/department.json";


export default {
  name : 'Downloadables',
  props : ['link'],
  components: {
    //PageHeader
  },
  data () {
    return {
       //downloadables : downloadablesData,
      //departments : departmentData,
      //departmentDownloadables:downloadablesData,
      downloadables:[],
      departments:[],
      departmentDownloadables:[],
      tempDepartment:[],
      departmentDetails:[],
      departmentDetails2:[],
      headers: [],
      tablename:this.$vnode.tag,
      items:[]
    
    }
  },
  mounted()  {
      
    this.tablename=this.tablename.replace(/vue-component-\d+-/i, '').toLowerCase();
    
    if(this.link=="ALL"){
      this.headers=[ {   
          text: 'DEPARTMENT NAME', 
          value: 'Department', 
          align: 'start',
        },
        { text: 'DOCUMENT NAME', 
          value: 'Url', 
          align: 'start',
        },
      ]
      //alert(this.downloadables)
      //this.departmentDownloadables=this.downloadables
      this.getAllRecords(`/getAllRecords/departments/where ID > 0/ID asc`,'departments');
      this.getAllRecords(`/getAllRecords/downloadables/where ID > 0/Department asc`,'departmentdownloadables');
      }
    else{
      this.headers=[ 
        { text: 'DOCUMENT NAME',
          value: 'Url',
          align: 'start',
         },
      ]
      
      this.getAllDependentRecords(`/getAllDependentRecords/downloadables/departments/Link/Department/Name/where Link='`+this.link+`'/ID asc`,'downloadables');
   //getAllDependentRecords/:tablename/:tablename2/:field2/:joinby/:joinby2/:filter/:order
    }
     
  },

  watch: {
    link () {
      
      //this.filterDepartment(this.link);
      //console.log(this.departmentDetails);
     
      if(this.link=="ALL"){
        this.headers=[ {   
            text: 'DEPARTMENT NAME', 
            align: 'start',
            value: 'link' 
          },
          { text: 'DOCUMENT NAME', value: 'Url' },
        ]
        this.departmentDownloadables=this.downloadables
      }
      else{
        this.headers=[ 
          { text: 'DOCUMENT NAME', value: 'Url' },
        ]
        this.departmentDownloadables=[]
        this.getAllDependentRecords(`/getAllDependentRecords/downloadables/departments/Link/Department/Name/where Link='`+this.link+`'/ID asc`,'downloadables');
   
      }
    }
  }, 

  methods : {

    filterDownloadables: function(Department) {
      //shortcut to get department Downloadables
      //alert(Department)
      if(this.link!=="ALL"){
        return this.downloadables.filter( xdownloadables => xdownloadables.Department==Department)
      }
      else{
        return this.downloadables
      }
    },

    filterDepartment: function(link) {
      this.departmentDetails=this.departments.filter( xdepartment => xdepartment.Link==link);
     
    },
      
      

    getAllRecords:function(query,table){
        //alert(query);       
        this.$axios.get( this.$api_url+query,{
            
          params: {
               
            }
        })
        .then(response => {
            //alert(Object.keys(response.data).length);
            if (Object.keys(response.data).length > 0) {
                
                if (table=='departments') {
                     this.departments=response.data;
                     //this.filterDepartment(this.link);
                     //alert(this.departments);
                }
                else if (table=='downloadables'){
                    this.downloadables=response.data;
                    //this.departmentDownloadables=response.data;
                }
                else if (table=='departmentdownloadables'){
                    this.departmentDownloadables=response.data;
                    //this.departmentDownloadables=response.data;
                }
                else if (table=='departmentdetails'){
                    this.departmentDetails2=response.data;
                    //alert(this.departmentDetails2);  
                    //this.departmentDownloadables=response.data;
                }
                
                
                //return response.data;
                //this.departments=response.data;
                //console.log(response.data);
                
              }
        })
        .catch(error => { console.log(error)})
        
    },  

    getAllDependentRecords:function(query,table){
        //alert(query);       
        this.$axios.get( this.$api_url+query,{
            
          params: {
               
            }
        })
        .then(response => {
            //alert(Object.keys(response.data).length);
            if (Object.keys(response.data).length > 0) {
                
                if (table=='departments') {
                     this.departments=response.data;
                     //this.filterDepartment(this.link);
                     //alert(this.departments);
                }
               else if (table=='downloadables'){
                    this.departmentDownloadables=response.data;
                    //this.departmentDownloadables=response.data;
                }
                
                //console.log(response.data);
                
              }
        })
        .catch(error => { console.log(error)})
        
    },  

  }


}
</script>

<style>
  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>